<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
  >
    <v-card
      class="action-modal"
      :class="{ 'br-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span>{{
          selected_voucher_id ? "Edit Voucher" : "Add New Voucher"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="action-modal-content">
          <v-form v-model="form_valid" ref="form">
            <v-row>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Voucher Code <span class="red--text">*</span>
                </p>
                <v-text-field
                  :disabled="selected_voucher_id !== null"
                  v-model="voucher.code"
                  :rules="rules_code"
                  dense
                  single-line
                  @input="(value) => format_voucher_code(value)"
                  maxlength="30"
                  counter="30"
                  required
                />
              </v-col>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Excluded Categories
                </p>
                <v-combobox
                  ref="$category_select"
                  :items="product_categories"
                  v-model="voucher.excluded_categories"
                  hide-details
                  dense
                  single-line
                  multiple
                />
              </v-col>

              <v-col cols="12">
                <v-radio-group
                  class="mt-0"
                  v-model="voucher.type"
                  :rules="rules_type"
                  hide-details
                  dense
                  single-line
                >
                  <template v-slot:label>
                    <p
                      class="
                        mb-0
                        headline
                        input-label
                        font-weight-bold
                        dark-grey--text
                      "
                    >
                      Discount Type <span class="red--text">*</span>
                    </p>
                  </template>
                  <v-container row>
                    <v-radio
                      label="Percentage Discount"
                      :value="VOUCHER_TYPES.PERCENT"
                      class="mb-0 mr-4"
                      hide-details
                      dense
                      single-line
                    ></v-radio>
                    <v-radio
                      label="Currency Discount"
                      :value="VOUCHER_TYPES.CURRENCY"
                      hide-details
                      dense
                      single-line
                    ></v-radio>
                  </v-container>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="voucher.type === VOUCHER_TYPES.PERCENT">
              <v-col cols="12" sm="3">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Discount (%) <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="voucher.discount_percentage"
                  type="number"
                  step="1"
                  min="1"
                  max="100"
                  :rules="rules_discount_percentage"
                  required
                  dense
                  single-line
                />
              </v-col>
              <v-col cols="12" sm="3">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Min. Spend (£)
                </p>
                <v-text-field
                  v-model="voucher.percent_minimum_spend"
                  type="number"
                  step="0.01"
                  min="0.01"
                  :rules="rules_percent_minimum_spend"
                  required
                  dense
                  single-line
                />
              </v-col>
              <v-col cols="12" sm="3">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Limit (£)
                </p>
                <v-text-field
                  v-model="voucher.discount_limit"
                  type="number"
                  step="0.01"
                  min="0.01"
                  :rules="rules_discount_limit"
                  required
                  hide-details
                  dense
                  single-line
                />
              </v-col>
            </v-row>

            <v-row v-if="voucher.type === VOUCHER_TYPES.CURRENCY">
              <v-col cols="12" sm="3">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Discount (£) <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="voucher.discount_value"
                  type="number"
                  step="0.01"
                  min="0.01"
                  :rules="rules_discount_value"
                  required
                  dense
                  single-line
                />
              </v-col>
              <v-col cols="12" sm="3">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Min. Spend (£) <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="voucher.currency_minimum_spend"
                  type="number"
                  step="0.01"
                  :min="voucher.discount_value"
                  :rules="rules_currency_minimum_spend"
                  required
                  dense
                  single-line
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="black" text rounded @click="(show = false), clear()">
          Close
        </v-btn>
        <v-btn
          elevation="0"
          color="primary"
          rounded
          @click="submit()"
          :disabled="!form_valid || loading"
        >
          <v-progress-circular
            v-if="loading"
            :size="20"
            indeterminate
            color="white"
          />
          <span v-else>{{
            selected_voucher ? "Save" : "Add new voucher"
          }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import Decimal from "decimal.js";

import { mapState, mapGetters } from "vuex";
import { db, firestore } from "@/db";
import { format_string_to_uid } from "@/utils/firestore";

import {
  field_req,
  txt_min_2_char,
  is_number,
  val_min_0,
  val_min_001,
  val_min_1,
  val_max_100,
  count_0_decimals,
  count_2_decimals,
} from "@/utils/form_val_rules";
import { VOUCHER_TYPES } from "@/shared/const";

export default {
  name: "VoucherForm",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selected_voucher: {
      type: Object,
      default: null,
    },
    duplicateVoucher: { type: Boolean, default: false },
  },
  data() {
    return {
      VOUCHER_TYPES,
      loading: false,
      form_valid: false,
      selected_voucher_id: null,
      voucher: {
        code: "",
        description: null,
        type: VOUCHER_TYPES.PERCENT,
        discount_value: null,
        currency_minimum_spend: null,
        discount_percentage: null,
        percent_minimum_spend: null,
        discount_limit: null,
        excluded_categories: null,
        enabled: true,
      },
      rules_type: [field_req],
      rules_percent_minimum_spend: [is_number, val_min_001, count_2_decimals],
      rules_discount_limit: [is_number, val_min_0, count_2_decimals],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapGetters("AdminStore", [
      "voucher_codes",
      "all_product_categories",
      "active_categories",
    ]),
    product_categories() {
      const active_product_categories = this.active_categories;
      return Object.keys(active_product_categories).map((cat_id) => ({
        text: this.all_product_categories[cat_id],
        value: cat_id,
      }));
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    rules_code() {
      return [field_req, txt_min_2_char, this.val_voucher_code_doesnt_exist];
    },
    rules_discount_value() {
      return [
        ...(this.voucher.type === VOUCHER_TYPES.CURRENCY ? [field_req] : []),
        is_number,
        val_min_001,
        count_2_decimals,
      ];
    },
    rules_currency_minimum_spend() {
      return [
        ...(this.voucher.type === VOUCHER_TYPES.CURRENCY ? [field_req] : []),
        is_number,
        val_min_001,
        count_2_decimals,
        this.val_cost_greater_than_discount,
      ];
    },
    rules_discount_percentage() {
      return [
        ...(this.voucher.type === VOUCHER_TYPES.PERCENT ? [field_req] : []),
        is_number,
        val_min_1,
        val_max_100,
        count_0_decimals,
      ];
    },
  },
  watch: {
    selected_voucher(voucher) {
      if (voucher) {
        let selected_voucher = { ...voucher };
        selected_voucher.excluded_categories = this.product_categories.filter(
          (cat) => selected_voucher.excluded_categories[cat["value"]]
        );

        if (this.duplicateVoucher) {
          selected_voucher.code = null;
          this.$emit("duplicate_complete");
        } else {
          this.selected_voucher_id = voucher.id;
        }
        this.voucher = selected_voucher;
      }
    },
  },
  methods: {
    format_voucher_code(value) {
      this.voucher.code = value.toUpperCase().replace(/\s/g, "-");
    },
    val_cost_greater_than_discount() {
      return (
        Number(this.voucher.currency_minimum_spend) >
          Number(this.voucher.discount_value) ||
        "Minimum bill must be greater than discount value"
      );
    },
    val_voucher_code_doesnt_exist() {
      let existing_voucher_codes = this.voucher_codes;
      if (this.selected_voucher) {
        existing_voucher_codes = existing_voucher_codes.filter(
          (code) => code != this.selected_voucher.code
        );
      }
      return (
        !existing_voucher_codes.includes(this.voucher.code) ||
        "Code must be unique to existing codes"
      );
    },
    async submit() {
      this.loading = true;
      if (this.selected_voucher_id) {
        await this.update_voucher();
      } else {
        await this.add_voucher();
      }
      this.loading = false;
      this.show = false;
      this.clear();
    },
    async add_voucher() {
      const format_case = "create";
      const voucher_id = format_string_to_uid(this.voucher.code);

      try {
        await db
          .collection("shops")
          .doc(this.shop_id)
          .collection("vouchers")
          .doc(voucher_id)
          .set(this.format_voucher(this.voucher, format_case));
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    async update_voucher() {
      const format_case = "update";
      const ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("vouchers")
        .doc(this.selected_voucher_id);

      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(ref, this.format_voucher(this.voucher, format_case));
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    clear() {
      this.selected_voucher_id = null;
      this.voucher = {
        code: "",
        description: null,
        type: VOUCHER_TYPES.PERCENT,
        discount_value: null,
        currency_minimum_spend: null,
        discount_percentage: null,
        percent_minimum_spend: null,
        discount_limit: null,
        excluded_categories: null,
        enabled: true,
      };
      this.reset_vals();
    },
    format_voucher(voucher, format_case) {
      const new_voucher = cloneDeep(voucher);

      if (new_voucher.type === VOUCHER_TYPES.PERCENT) {
        new_voucher.discount_percentage = Number(
          Decimal(new_voucher.discount_percentage).toFixed(0)
        );
        if (new_voucher.discount_limit) {
          new_voucher.discount_limit = Number(
            Decimal(new_voucher.discount_limit).toFixed(2)
          );
        }
        if (new_voucher.percent_minimum_spend) {
          new_voucher.percent_minimum_spend = Number(
            Decimal(new_voucher.percent_minimum_spend).toFixed(2)
          );
        }
        new_voucher.discount_value = null;
        new_voucher.currency_minimum_spend = null;
      } else if (new_voucher.type === VOUCHER_TYPES.CURRENCY) {
        new_voucher.discount_value = Number(
          Decimal(new_voucher.discount_value).toFixed(2)
        );
        new_voucher.currency_minimum_spend = Number(
          Decimal(new_voucher.currency_minimum_spend).toFixed(2)
        );
        new_voucher.discount_percentage = null;
        new_voucher.percent_minimum_spend = null;
        new_voucher.discount_limit = null;
      }

      new_voucher.excluded_categories =
        new_voucher.excluded_categories?.reduce((categories, cat) => {
          return {
            ...categories,
            [cat["value"]]: true,
          };
        }, {}) || {};

      if (format_case === "create") {
        new_voucher.created_at = firestore.FieldValue.serverTimestamp();
      }
      new_voucher.updated_at = firestore.FieldValue.serverTimestamp();
      return new_voucher;
    },
    reset_vals() {
      this.$refs.form.resetValidation();
      this.$refs.$category_select.reset();
    },
  },
};
</script>
