<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0 mb-4">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search_term"
            append-icon="mdi-magnify"
            label="Search by Voucher Code"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-end align-end">
          <v-btn
            class="mr-0"
            color="yellow"
            rounded
            @click="show_voucher_form = true"
          >
            + Add new voucher
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="vouchers"
      :search="search_term"
      :items-per-page="10"
      :item-class="table_row_class"
      item-key="id"
      class="br-8"
    >
      <template v-slot:header.enabled="{ header }">
        <v-tooltip bottom content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mr-2">mdi-information-outline</v-icon>
            <span>{{ header.text }}</span>
          </template>
          <span>
            Enable and disable your vouchers easily by switching the toggle on
            and off.
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.discount="{ item }">
        <p class="mb-0">
          {{
            item.discount_percentage
              ? `${item.discount_percentage}%`
              : `-£${item.discount_value}`
          }}
        </p>
      </template>

      <template v-slot:item.enabled="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            color="primary"
            v-model="item.enabled"
            @change="toggle_enabled(item)"
          ></v-switch>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-row justify-space-around">
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                :class="{
                  'mr-8': $vuetify.breakpoint.smAndDown,
                }"
                @click="handle_edit(item)"
              >
                <v-icon small color="dark-grey" v-text="'$edit'" />
              </div>
            </template>
            Edit
          </v-tooltip>
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                :class="{
                  'mr-8': $vuetify.breakpoint.smAndDown,
                }"
                @click="handle_duplicate_voucher(item)"
              >
                <v-icon small color="dark-grey" v-text="'$copy'" />
              </div>
            </template>
            Duplicate
          </v-tooltip>
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                @click="handle_delete(item)"
              >
                <v-icon small color="accent" v-text="'$delete'" />
              </div>
            </template>
            Delete
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        <p>Sorry no vouchers, try to add some</p>
      </template>
    </v-data-table>

    <VoucherForm
      :visible="show_voucher_form"
      :selected_voucher="selected_voucher"
      :duplicate-voucher="duplicate_voucher"
      @duplicate_complete="duplicate_voucher = false"
      @close="
        show_voucher_form = false;
        selected_voucher = null;
      "
    />
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { firestore } from "firebase/app";

import { db } from "@/db";
import VoucherForm from "../forms/VoucherForm";

export default {
  name: "Vouchers",
  components: {
    VoucherForm,
  },
  data() {
    return {
      search_term: "",
      show_voucher_form: false,
      selected_voucher: null,
      duplicate_voucher: false,
      headers: [
        {
          text: "Voucher Code",
          align: "start",
          value: "code",
        },
        {
          text: "Discount",
          align: "start",
          value: "discount",
          sortable: false,
        },
        { text: "Status", value: "enabled", align: "center" },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id", "product_categories"]),
    ...mapGetters("AdminStore", ["vouchers"]),
  },
  methods: {
    table_row_class(item) {
      if (!item.enabled) return "not-enabled";
    },
    async delete_voucher(voucher) {
      const ref = this.get_voucher_document_reference(voucher);
      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.delete(ref);
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    async update_voucher_field(voucher, field, value) {
      const ref = this.get_voucher_document_reference(voucher);
      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(ref, {
            [field]: value,
            updated_at: firestore.FieldValue.serverTimestamp(),
          });
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    format_created_at_date(created_at) {
      if (!created_at) return;
      return moment.unix(created_at).format("DD/MM/YYYY");
    },
    toggle_enabled(voucher) {
      this.update_voucher_field(voucher, "enabled", voucher.enabled);
    },
    get_voucher_document_reference(voucher) {
      return db
        .collection("shops")
        .doc(this.shop_id)
        .collection("vouchers")
        .doc(voucher.id);
    },
    handle_duplicate_voucher(voucher) {
      this.duplicate_voucher = true;
      this.selected_voucher = voucher;
      this.show_voucher_form = true;
    },
    handle_edit(voucher) {
      this.selected_voucher = voucher;
      this.show_voucher_form = true;
    },
    async handle_delete(voucher) {
      const { value: accept } = await Swal.fire({
        title: "Delete",
        text: `Are you sure you want to delete voucher ${voucher.code}?`,
        confirmButtonText: "Delete",
        confirmButtonColor: "#54c4c9",
        showCancelButton: true,
      });

      if (accept) {
        this.delete_voucher(voucher);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
::v-deep .not-enabled {
  color: #c9c9c9;
}
</style>
