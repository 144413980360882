var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-container',{staticClass:"py-0 mb-4",attrs:{"fluid":"","tag":"section"}},[_c('v-row',{staticClass:"secondary px-4 br-8 pt-0 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Voucher Code","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search_term),callback:function ($$v) {_vm.search_term=$$v},expression:"search_term"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":""},on:{"click":function($event){_vm.show_voucher_form = true}}},[_vm._v(" + Add new voucher ")])],1)],1)],1),_c('v-data-table',{staticClass:"br-8",attrs:{"headers":_vm.headers,"items":_vm.vouchers,"search":_vm.search_term,"items-per-page":10,"item-class":_vm.table_row_class,"item-key":"id"},scopedSlots:_vm._u([{key:"header.enabled",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-information-outline")]),_c('span',[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(" Enable and disable your vouchers easily by switching the toggle on and off. ")])])]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.discount_percentage ? ((item.discount_percentage) + "%") : ("-£" + (item.discount_value)))+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.toggle_enabled(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",class:{
                'mr-8': _vm.$vuetify.breakpoint.smAndDown,
              },on:{"click":function($event){return _vm.handle_edit(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"dark-grey"},domProps:{"textContent":_vm._s('$edit')}})],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",class:{
                'mr-8': _vm.$vuetify.breakpoint.smAndDown,
              },on:{"click":function($event){return _vm.handle_duplicate_voucher(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"dark-grey"},domProps:{"textContent":_vm._s('$copy')}})],1)]}}],null,true)},[_vm._v(" Duplicate ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",on:{"click":function($event){return _vm.handle_delete(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"accent"},domProps:{"textContent":_vm._s('$delete')}})],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Sorry no vouchers, try to add some")])]},proxy:true}])}),_c('VoucherForm',{attrs:{"visible":_vm.show_voucher_form,"selected_voucher":_vm.selected_voucher,"duplicate-voucher":_vm.duplicate_voucher},on:{"duplicate_complete":function($event){_vm.duplicate_voucher = false},"close":function($event){_vm.show_voucher_form = false;
      _vm.selected_voucher = null;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }